import React from 'react';
import styled from 'styled-components';

const ShopItem = styled.div`
  width: 100%;
  border: none;
  height: 100%;
  border-bottom: 1px solid hsla(0, 0%, 84.7%, 0.4);
  padding: 5px 0;
  padding-left: 5px;
  height: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
`;

const ProductImageContainer = styled.div`
  width: 35%;
  height: 106px;
  border-radius: 13px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding-left: 10px;
  justify-content: flex-start;
`;

const ProductMoreIcon = styled.div`
  width: 30px;
`;

const Price = styled.span`
  font-size: 14px;
  padding-left: 1px;
  font-weight: normal;
  color: black;
  padding-bottom: 0.5rem;
`;

const ProductDescription = styled.p`
  width: 95%;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 10px;
  color: black;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function ProductContainer({
  images,
  currencySign,
  price,
  product_name,
  goTo,
}) {
  return (
    <ShopItem onClick={goTo}>
      <ProductImageContainer img={images}>
        <img
          src={images[0]}
          alt="product"
          style={{
            width: '90%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 13,
          }}
        />
      </ProductImageContainer>

      <DescriptionContainer>
        <Price>
          {currencySign} {price}
        </Price>
        <ProductDescription>{product_name}</ProductDescription>
      </DescriptionContainer>

      <ProductMoreIcon>
        <img src="arrow.svg" alt="more" />
      </ProductMoreIcon>
    </ShopItem>
  );
}
