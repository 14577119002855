import React from 'react';
import './videos.css';
import { useQuery } from 'react-query';
import { API_BASE_URL, headers } from '../../constants';
import { useLocation } from 'react-router-dom';
import Video from './video';

const usePQD = () => {
    return new URLSearchParams(useLocation().search);
}

export default function Videos ({status}) {
    const pqd = usePQD().get('pqd');
    const {isLoading, error, data=0} = useQuery('videos', async () => {
        var res = await fetch(`${API_BASE_URL}/videos.json`, {
            method: 'POST',
            body: JSON.stringify({
              pqd,
              page: 1,
            }),
            headers
          });
        res = await res.json()
        return res.data;
    });

    if (isLoading) {
        return (
            <div className="parent-container">
                <div className="loading-container">
                    <img src="loading.svg" alt="loading..." />
                </div>
            </div>
        )
    }

    if (error) {
        return(
            <h1>{error}</h1>
        )
    }

    const VideoGenerator = () => {
        if(data.length > 0) {
            if(data.filter((video) => {
                if ((status === 'draft')) {
                    return (video.attributes.status === 'draft') || (video.attributes.status === 'upcoming')
                }

                return video.attributes.status === status
            }).length === 0) {
                return (
                    <div className="empty-container">
                        <div>
                            <img src="empty_space.svg" alt="empty videos" />
                        </div>
                        <h1 style={{color: '#6b6b6b', fontSize: 20, fontWeight: 600}}>No items to display</h1>
                    </div>
                )
            }

            return (<div className="videos-container">
                {data.filter((video) => {
                    if ((status === 'draft')) {
                        return (video.attributes.status === 'draft') || (video.attributes.status === 'upcoming')
                    }

                    return video.attributes.status === status
                }).map((video) => <Video details={video.attributes} title={video.attributes.title} id={video.id} key={video.id} image={video.attributes.banner_url}/>)}
            </div>)
        }
        return (<div className="empty-container">
            <div>
                <img src="empty_space.svg" alt="empty videos" />
            </div>
            <h1 style={{color: '#6b6b6b', fontSize: 20, fontWeight: 600}}>No items to display</h1>
        </div>)
    }

    return (
    <div className="parent-container" >
        {<VideoGenerator />}
    </div>
    )
}