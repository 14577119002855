/*
    Param: currency:string
    Param default: 'USD'
    return: currencySign:string eg. '$'
*/
export const getCurrencySign = (currency = 'USD') => {
  let currencySign = '$';

  if (currency === 'EUR') {
    currencySign = '€';
  }

  return currencySign;
};

/*
    Param: variant:object
    Param default: undefined
    return: variantOptionStr:string eg. 'Blue / M / Cotton'
*/
export const getVariantOptions = (variant) => {
  let variantOptionStr = '';

  if (variant.option1) {
    variantOptionStr = `${variant.option1}`;
  }

  if (variant.option1 && variant.option2) {
    variantOptionStr = `${variant.option1} / ${variant.option2}`;
  }

  if (variant.option1 && variant.option2 && variant.option3) {
    variantOptionStr = `${variant.option1} / ${variant.option2} / ${variant.option3}`;
  }

  return variantOptionStr;
};
