import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { getCurrencySign, getVariantOptions } from '../../helpers';
import { toast } from 'react-toastify';

const VariantDropdownOverlay = ({ handleToggle }) => {
  return ReactDOM.createPortal(
    <aside
      className="variant-dropdown-overlay-desktop"
      onClick={handleToggle}
    ></aside>,
    document.getElementById('integrated-product-container-desktop')
  );
};

const IntegratedProductDetailsPage = ({
  lineItemId,
  images,
  currency,
  price,
  name,
  variants,
  addProductToCart,
  removeCartItem,
  viewer_id,
  cartlineItems,
  addToCartText,
  removeFromCartText,
  itemAddedText,
  itemRemovedText,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);
  const [variantImage, setVariantImage] = useState(images[0]);
  const [variantPrice, setVariantPrice] = useState(price);
  const [selectedVariantOptionStr, setSelectedVariantOptionStr] = useState('');
  const [isVariantsDropdownOpen, setIsVariantsDropdownOpen] = useState(false);
  const [isVariantAlreadyInCart, setIsVariantAlreadyInCart] = useState(false);
  const [isWaitingForRes, setIsWaitingForRes] = useState(false);

  const handleVariantsDropdownOnClick = () => {
    setIsVariantsDropdownOpen(!isVariantsDropdownOpen);
  };

  const handleVariantOptionItemOnClick = (variant_id) => {
    const variant = variants.find((v) => v._id.$oid === variant_id);
    setSelectedVariant(variant);
    setIsVariantsDropdownOpen(!isVariantsDropdownOpen);
  };

  useEffect(() => {
    function checkIfVariantIsAlreadyInCart() {
      if (cartlineItems.length === 0) {
        return;
      }

      // re-initialize
      setIsVariantAlreadyInCart(false);

      const variantIDsInCart = cartlineItems.map(
        (lineItem) => lineItem.variant_id.$oid
      );

      let variantInCart;

      for (let i = 0; i < variantIDsInCart.length; i++) {
        variantInCart = variants.find(
          (variant) => variant._id.$oid === variantIDsInCart[i]
        );

        if (variantInCart) {
          break;
        }
      }

      if (variantInCart) {
        setIsVariantAlreadyInCart(true);
        setSelectedVariant(variantInCart);
      }
    }

    checkIfVariantIsAlreadyInCart();
  }, [cartlineItems, lineItemId, variants]);

  useEffect(() => {
    setVariantImage(selectedVariant.image);
    setVariantPrice(selectedVariant.price);
    setSelectedVariantOptionStr(getVariantOptions(selectedVariant));
  }, [selectedVariant]);

  useEffect(() => {
    setSelectedVariant(variants[0]);
    setVariantImage(images[0]);
    setVariantPrice(price);
  }, [lineItemId, images, price, variants]);

  const handleAddToCartOnClick = () => {
    setIsWaitingForRes(true);

    addProductToCart({
      variant_id: selectedVariant._id.$oid,
      quantity: 1,
      viewer_id,
    })
      .then((res) => {
        setIsWaitingForRes(false);
        setIsVariantAlreadyInCart(true);
        toast.success(itemAddedText);
      })
      .catch((error) => {
        toast.error('Server error.');
        setIsWaitingForRes(false);
      });
  };

  const handleRemoveItemFromCartOnClick = () => {
    setIsWaitingForRes(true);

    const lineItemFromCart = cartlineItems.find(
      (lineItem) => lineItem.variant_id.$oid === selectedVariant._id.$oid
    );

    removeCartItem(lineItemFromCart._id.$oid)
      .then((res) => {
        setIsWaitingForRes(false);
        setIsVariantAlreadyInCart(false);
        toast.success(itemRemovedText);
      })
      .catch((error) => {
        setIsWaitingForRes(false);
        toast.error('Server error.');
      });
  };

  return (
    <>
      {isVariantsDropdownOpen && (
        <VariantDropdownOverlay handleToggle={handleVariantsDropdownOnClick} />
      )}

      <div
        id="integrated-product-container-desktop"
        className="integrated-product-container-desktop"
      >
        <div className="image-integrated-product-container-desktop">
          <img
            className="image-integrated-product"
            src={variantImage}
            alt="Foamie"
          />
        </div>
        <div className="price-and-name-container-desktop">
          <span>
            {getCurrencySign(currency)} {variantPrice}
          </span>
          <p>{name}</p>
        </div>

        {variants.length > 1 && (
          <div className="variants-dropdown-container-desktop">
            <button
              type="button"
              className="variants-dropdown-button-desktop"
              onClick={handleVariantsDropdownOnClick}
              disabled={isVariantAlreadyInCart}
            >
              <span>{selectedVariantOptionStr || 'Select'}</span>
              <svg
                className="variant-dropdown-chevron-down-svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </button>

            {isVariantsDropdownOpen && (
              <div className="variants-list-container">
                {variants.map((variant) => {
                  return (
                    <button
                      key={variant._id.$oid}
                      className={
                        selectedVariant._id.$oid === variant._id.$oid
                          ? 'variant-item-button-selected'
                          : 'variant-item-button'
                      }
                      type="button"
                      onClick={() =>
                        handleVariantOptionItemOnClick(variant._id.$oid)
                      }
                    >
                      <span>{getVariantOptions(variant)}</span>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {isWaitingForRes ? (
          <button
            type="button"
            className="call-to-action-integrated-product-button-desktop"
            disabled={true}
          >
            <div className="loader-btn"></div>
          </button>
        ) : (
          <>
            {isVariantAlreadyInCart ? (
              <button
                type="button"
                className="call-to-action-integrated-product-button-desktop"
                onClick={handleRemoveItemFromCartOnClick}
              >
                <span>{removeFromCartText}</span>
              </button>
            ) : (
              <button
                type="button"
                className="call-to-action-integrated-product-button-desktop"
                onClick={handleAddToCartOnClick}
              >
                <span>{addToCartText}</span>
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default IntegratedProductDetailsPage;
