import {API_BASE_URL, headers} from '../constants';

async function fetcher(id) {
   let res = await fetch(`${API_BASE_URL}/videos.json`, {
        method: 'POST',
        body: JSON.stringify({
          pqd: id,
          page: 1,
        }),
        headers
      });
      res = (await res.json()).data;
      return res;

}

async function addToCart({variant_id, quantity, viewer_id}) {
  let res = await fetch(`${API_BASE_URL}/line_items.json`, {
    method: 'POST',
    body: JSON.stringify({
      variant_id,
      quantity,
      viewer_id
    }),
    headers
  })

  res = (await res.json()).data;
  return res
}

async function updateCart({line_item_id, quantity}) {
  let res = await fetch(`${API_BASE_URL}/line_items/${line_item_id}.json`, {
    method: 'PATCH',
    body: JSON.stringify({
      quantity
    }),
    headers
  })

  res = (await res.json()).data;
  return res
}

async function deleteItem(line_item_id) {
  let res = await fetch(`${API_BASE_URL}/line_items/${line_item_id}.json`, {
    method: 'DELETE',
    headers
  })
  res = (await res.json()).data;
  return res
}

export async function viewerCart(viewer_id) {
  let res = await fetch(`${API_BASE_URL}/carts/${viewer_id}.json`, {
    method: 'GET',
    headers
  })

  res = (await res.json()).data;
  return res
}

export async function deleteCart(viewer_id) {
  let res = await fetch(`${API_BASE_URL}/carts/${viewer_id}.json`, {
    method: 'DELETE',
    headers
  })
  
  res = (await res.json()).success
  return res
}

export function deleteViewerCart(viewer_id) {
  return function(dispatch) {
    return deleteCart(viewer_id).then(res => {
      if (res) {
        dispatch({type: 'DELETE_CART', payload: []})
      }
    })
  }
}

export function updateCartItem({line_item_id, quantity}) {
  return function (dispatch) {
    return updateCart({line_item_id, quantity}).then(res => {
      if(res) {
        dispatch({type: 'ADD_TO_CART', payload: {
          lineItems: res.attributes.line_items,
          total: res.attributes.net_total
        }})
      }
    })
  }
}

export function addProductToCart({variant_id, quantity, viewer_id}) {
  return function(dispatch) {
    return addToCart({variant_id, quantity, viewer_id}).then(res => res).then(response => {
      if (response) {
        dispatch({type: 'ADD_TO_CART', payload: {
          lineItems: response.attributes.line_items,
          total: response.attributes.net_total
        }})
        return true
      }
    })
  }
}

export function removeCartItem(line_item_id) {
  return function(dispatch) {
    return deleteItem(line_item_id).then(res => {
      if(res.type === 'cart') {
        dispatch({type: 'ADD_TO_CART', payload: {
          lineItems: res.attributes.line_items,
          total: res.attributes.net_total
        }})
        return true
      }
    })
  }
}

export default function fetchVideos(id) {
    return function(dispatch, getState) {
        return fetcher(id).then(res => res).then((response) => {
            dispatch({type: 'FETCH_VIDEOS', payload: response})
            return getState(); 
        })
    }
}