export const API_BASE_URL = "https://api.playback.ai/api/v1";
export const API_X_PQ_APP = "eyJhbGciOiJub25lIn0.eyJkYXRhIjoiNWU5ODg5MGY0MDYxNDkxNzMwOGQ0MDllIn0.";
export const BRAND_ID = '5f21f7f64061494c55069332';
export const headers = {
                            "Content-Type": "application/json",
                            'Accept': 'application/json',
                            'X-PQ-App': API_X_PQ_APP
                        };
export const DRAFT = 'draft';
export const RECORDED = 'recorded';
export const UPCOMING = 'upcoming';
export const LIVE = 'live';