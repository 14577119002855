import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import videosReducer from './videosReducer';
import authReducer from './authReducer';
import cartReducer from './cartReducer';

const authPersistConfig = {
    key: 'auth',
    storage
}

const cartPersistConfig = {
    key: 'cart',
    storage
}

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    video: videosReducer,
    cart: persistReducer(cartPersistConfig, cartReducer)
})

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    let store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
    let persistor = persistStore(store);
    return { store, persistor }
}
