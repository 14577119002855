import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryClientProvider, QueryClient} from 'react-query'
import { PersistGate } from 'redux-persist/integration/react';
import Home from './routes/home/index';
import Play from './routes/play/index';
import Register from './routes/register/registerViewer';
import { Provider } from 'react-redux';
import configStore from './store/configureStore';
import './App.css';

import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const queryCache = new QueryClient({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false
    },
    cacheTime: 0
  }
});

const {store, persistor} = configStore();

function App() {
  return (
    <QueryClientProvider client={queryCache}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router forceRefresh={false}>
            <ToastContainer
              autoClose={3000}
              draggable={false}
              newestOnTop={true}
              transition={Slide}
              closeButton={false}
              hideProgressBar
              position="top-center"
              toastClassName="toast-notification"
              limit={3}
            />
            <Switch>  
              <Route path="/" exact component={Home} />
              <Route path="/play" exact component={Play} />
              <Route path="/register" component={Register} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
