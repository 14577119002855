const initialState = {
    viewer: {},
    isAuthenticated: false,
    pqd: null,
    intendedRoute: null,
    viewer_id: null
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case 'REGISTER_VIEWER':
            return {...state, viewer: action.payload, isAuthenticated: true}
        case 'SET_PQD':
            return {...state, pqd: action.payload}
        case 'SET_INTENDED_ROUTE':
            return {...state, intendedRoute: action.payload}
        case 'SET_VIEWER_ID':
            return {...state, viewer_id: action.payload}
        default:
            return state
    }
}

export default reducer
