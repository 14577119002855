import React from 'react';
import styled from 'styled-components';

const ShopItem = styled.div`
    width: 70%;
    border-radius: 5px;
    height: 80px;
    background-color: white;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
`

const ProductImageContainer = styled.div`
    width: 100%;
    height: 82%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: url("${props => props.img}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`
const DescriptionContainer = styled.div`
    height: 20px;
    width: 100%;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #6b6b6b;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
    white-space: nowrap;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis; 
`

const Description = styled.span`
    font-size: 15px;
    font-weight: 500;
    color: white;
    @media screen and (max-width: 39.9375em) {
        font-size:12px;
    }
`

export default function ContextProduct ({image_url,description, goTo, key, currency}) {
    return (
        <ShopItem onClick={goTo} key={key}>
            <ProductImageContainer img={image_url}></ProductImageContainer>
            <DescriptionContainer>
                <Description><span>{ isNaN(1 + description) ? description : `${currency}${description}`}</span></Description>
            </DescriptionContainer>
        </ShopItem>
    )
}
