import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import ViewerModal from '../../components/ViewerModal';
import './registerViewer.css';

function RegisterViewer({isAuthenticated, pqd_state, viewer}) {
    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [])

    if (!isAuthenticated) {
        return (
            <div className="register-container" style={{width: '100%', maxWidth: 1140, margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ViewerModal />
            </div>
        )
    }
    
    if (viewer && (sessionStorage.getItem('intendedRoute'))) {
        var route = sessionStorage.getItem('intendedRoute');
        return (
            <Redirect 
            to={{
                pathname: "/play",
                search: route
            }}
            />
        )
    }
        return (
            <Redirect 
        to={{
            pathname: "/",
            search: `?pqd=${pqd_state}`
        }}
        />
        )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        pqd_state: state.auth.pqd,
        intendedRoute: state.video.intendedRoute,
        viewer: state.auth.viewer
    }
}

export default connect(mapStateToProps,null)(RegisterViewer)