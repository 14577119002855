const initialState = {
    total: 0,
    lineItems: []
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case 'ADD_TO_CART':
            const { lineItems, total } = action.payload
            return {...state, lineItems, total}
        case 'DELETE_CART':
            return {...state, lineItems: [], total: 0}
        default:
            return state
    }
}

export default reducer