import React from 'react';
import styled from 'styled-components';

const ShopItem = styled.div`
  width: 5rem;
  border-radius: 5px;
  height: 80px;
  background-color: white;
  cursor: pointer;
  margin-left: 1rem;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ProductImageContainer = styled.div`
  width: 100%;
  height: 82%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: url('${(props) => props.img}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const DescriptionContainer = styled.div`
  height: 20px;
  width: 100%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #6b6b6b;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  white-space: nowrap;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: white;
  @media screen and (max-width: 39.9375em) {
    font-size: 12px;
  }
`;

export default function ContextProductWeb({
  image_url,
  handleContextProductOnClick,
  description,
  currencySign,
}) {
  return (
    <ShopItem onClick={handleContextProductOnClick}>
      <ProductImageContainer img={image_url}></ProductImageContainer>
      <DescriptionContainer>
        <Description>
          <span>
            {isNaN(1 + description)
              ? description
              : currencySign + ' ' + description}
          </span>
        </Description>
      </DescriptionContainer>
    </ShopItem>
  );
}
