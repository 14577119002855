import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './video.css';

const useURLParam = () => {
    return new URLSearchParams(useLocation().search);
}

export default function Video (props) {
    const pqd = useURLParam().get('pqd');
    const style = {
        backgroundImage: `url("${props.image}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        opacity: 0.7
    }
    //style={{...style}}
    return (
    <Link to={`/play?pqd=${pqd}&pgpqvid=${props.id}`}> 
    <div style={{height: '100%', boxShadow: '0px 0px 12px #00000029'}}>
        <div className="video-component" style={{...style}}>
            <img src="play.svg" alt="play" width="18" height="20" />
        </div>
        <div className="video-title-container">
            <span>{props.title}</span>
        </div>
    </div>
    </Link>
    )
}