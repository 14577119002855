import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {connect} from 'react-redux';
import moment from 'moment'
// import {UPCOMING} from "../constants";
import {UPCOMING, RECORDED, LIVE} from "../constants";
import Button from 'react-bootstrap-button-loader';

const RegisterContainer = styled.div`
    width: 80%;
    height: 100%;
    max-width: 1140px;
    box-shadow: 0px 3px 25px #00000017;
    background-color: white;
    position: relative;
    @media screen and (max-width: 39.9375em) {
        width: 100%;
    }
`

const HeaderMessage = styled.h1`
    font-size: 20px;
    margin: 0 auto;
    text-align: center;
    color: #FFFFFF;
    padding-top: 25px;
    font-weight: 600;
    line-height: 26px;
    font-family: FenomenSans-SemiBold;
    @media screen and (max-width: 39.9375em) {
        width: 95%;
    }
`
const HeaderMessageSub = styled.p`
    font-size: 14px;
    margin: 0 auto 10px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 300;
    font-family: FenomenSans-Light;
    @media screen and (max-width: 39.9375em) {
        width: 95%;
    }
`

const EventTime = styled.strong`
    font-size: 20px;
    margin: 0 auto 15px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    display: block;
    font-family: FenomenSans-SemiBold;
    @media screen and (max-width: 39.9375em) {
        width: 95%;
    }
`

// const ImageContainer = styled.img`
//     width: 75%;
//     text-align: center;
//     margin: 0 auto;
//     display: block;
//     height: 519px;
// `

const Form = styled.form`
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom:20px;
    display: flex;
    flex-flow: column;
    align-content: center;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 39.9375em) {
        width: 95%;
        display: flex;
        flex-flow: column;
        align-content: center;
        align-items: center;
    }
    div{
        @media screen and (max-width: 39.9375em) {
            width:100%;
        }
    }
`

// const Button1 = styled.button`
//     border: 1px solid #1D1D1D;
//     background: #1D1D1D;
//     outline: none;
//     color: white;
//     cursor: pointer;
//     border-radius: 87px;
//     width: 135px;
//     height: 44px;
//     width: 135px;
//     padding: 10px 50px;
//     display: inline-block;
//     text-transform: uppercase;
// `

const Input = styled.input`
    margin-bottom: 20px;
    width: 100%;
    height: 20px;
    font-size: 16px;
    bottom: 1px solid #6b6b6b;
    @media screen and (max-width: 39.9375em) {
        width: 100% !important;
      }
`
const Validation = styled.p`
    color: red;
    margin-bottom: 20px;
    font-size: 14px;
    text-align:center;
`

const TopSection = styled.div`
    height: 400px;
    background: #000;
    margin-bottom: 200px;
    width: 100%;
    margin: 0px auto;
    display: block;
    z-index: -1;
    @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 568px)
      and (-webkit-min-device-pixel-ratio: 2) {
        height: 300px;
    }
   
    @media screen and (max-width: 39.9375em) {
        width: 100%;
        height:200px;
      }
      @media only screen 
      and (min-device-width: 375px) 
      and (max-device-width: 667px) 
      and (-webkit-min-device-pixel-ratio: 2) { 
        height: 250px;
    }
    video{
        width: 75%;
        margin: 0 auto;
        display: block;
        max-height: 519px;
        height: 519px;
      
        @media screen and (max-width: 39.9375em) {
            width: 95%;
      }
    }
`

const Title = styled.label`
    letter-spacing: 0px;
    color: #1D1D1D;
    text-transform: capitalize;
    font-size: 18px;
    margin: 0px auto;
    display: block;
    margin-top: 25px;
    text-align: center;
    font-weight: 600;
    width: 75%;
    font-family: FenomenSans-SemiBold;
    @media screen and (max-width: 39.9375em) {
        width: 95%;
    }
`

const Content = styled.p`
    letter-spacing: 0px;
    color: #1D1D1D;
    font-size: 16px;
    margin: 0px auto;
    display: block;
    margin-top: 10px;
    text-align: center;
    font-weight: 300;
    width: 65%;
    font-family: FenomenSans-Light;
    @media screen and (max-width: 39.9375em) {
        width: 95%;
        padding: 0;
    }
`

const ThanksMessage = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000029;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 15px 0px;
    text-align: center;
    height:90px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
        @media screen and (max-width: 39.9375em) {
            height:110px;
            align-items: flex-end;
        }
    p{
        width: 100%;
        font-size: 15px;
        margin: 0 auto;
        font-family: FenomenSans-SemiBold;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        justify-items: center;
        img{
            padding-right:10px;
            @media screen and (max-width: 39.9375em) {
                padding:0px;
                padding-bottom:10px;
            }
        }
        @media screen and (max-width: 39.9375em) {
            flex-flow:column;
            font-size:14px;
        }
    }

    }
    @media screen and (max-width: 39.9375em) {
        width: 100%;
        padding: 5px 10px;
        p{
            width: 100%;
        }
    }
`

const ImageWrap = styled.div`
    width: 80%;
    height: 500px;
    margin:auto;
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    margin-top: -280px;
    -webkit-box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.43);
    -moz-box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.43);
    box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.43);
    @media screen and (max-width: 39.9375em) {
        height: 20vh;
        margin-top: -100px;
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) { 
        height: 25vh;
        margin-top: -130px;
  }
`

const CloseIcon = styled.div`
    height: 1.6875rem;
    width: 1.6875rem;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2rem;
    margin-right: 0.875rem;
    z-index: 10;
    use-select: none
`

function ViewerModal({registerViewer}) {
    var email = useRef('');
    var name = useRef('');
    var button = React.createRef();
    const [validateEmail, setValidateEmail] = useState(true);
    const [validateName, setValidateName] = useState(true);
    const [showMessage, setShowMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const data = location && location.state.currentVideo

    const [eventStatusTitleText, setEventStatusTitleText] = useState('');

    // Sets the eventStatusTitleText state againt video status
    useEffect(() => {
        if (data.status === UPCOMING) {
            setEventStatusTitleText(data.event_upcoming_title_text)
            console.log(data.scheduled_time)
        } else if (data.status === LIVE) {
            setEventStatusTitleText(data.event_live_title_text)
        } else if (data.status === RECORDED) {
            setEventStatusTitleText(data.event_recorded_title_text)
        }
    }, [data.status,
        data.event_upcoming_title_text,
        data.event_live_title_text,
        data.event_recorded_title_text,
        data.scheduled_time
    ]);

    useEffect(() => {
        function getSessionStorageIntendedRoute() {
            sessionStorage.getItem('intendedRoute')
        }

        getSessionStorageIntendedRoute();
    }
    , []);


    const  randomString = (length, chars) => {
        let result = '';
        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    const handleSubmit = (submit) => {
        let rString = randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        let viewer_name;
        let viewer_email;

        if (name.current.value === '' && email.current.value === '') {
            setLoading(true);
            viewer_name = 'Guest';
            viewer_email = `guest${rString}@unknown.com`;
            return setTimeout(() => submit(viewer_name, viewer_email), 3500)
        }

        if (name.current.value !== '' && email.current.value !== '' && validateEmail) {
            setLoading(true);
            viewer_name = name.current.value;
            viewer_email = email.current.value;
            return setTimeout(() => submit(viewer_name, viewer_email), 3500)
        }

        if (name.current.value !== '' && email.current.value === '') {
            setValidateEmail(false)
            return null
        }

        if (name.current.value === '' && email.current.value !== '') {
            setValidateName(false)
            return null
        }
        
    }

    const handleClose = () => {
        setShowMessage(false)
    }

    return(
        <RegisterContainer >
            {/* <img style={{paddingTop: '35px', paddingBottom: '35px', width: '170px', display: 'inherit', margin: '0 auto'}} src={data.logo} alt="brandlogo" /> */}
            <div className="brandLogoWrap" style={{backgroundImage: `url(${data.logo})`, height: '110px'}}></div>

            <CloseIcon 
                onClick={() => window.parent.postMessage('close', '*')}
            >
                <img style={{userSelect: 'none'}} src="close.svg" alt="close" />
            </CloseIcon>

            <TopSection>
                <HeaderMessage>{data.brand_name}</HeaderMessage>
                <HeaderMessageSub>{eventStatusTitleText}</HeaderMessageSub>
                <EventTime>{data.scheduled_time !== null ? moment(data.scheduled_time).local().format('DD MMMM, Y HH:mm a') : moment(data.created_at).local().format('DD MMMM,  Y HH:mm a')}</EventTime>
            </TopSection>
                <ImageWrap style={{backgroundImage: `url(${data.banner_url})`}}/>
                {data.title !== null && (
                <Title>{data.title}</Title>
                )}
                {data.description !== null && (
                    <Content>{data.description}</Content>
                )}
                <Form>
                    <div style={{ width: '80%' }}>
                        <Input
                        onChange={e => {
                            if (e.target.value === '' || e.target.value.length > 0) {
                                setValidateName(true)
                            }
                        }}
                        onKeyPress={(e) => {
                            if(e.key === 'Enter') {
                                handleSubmit(registerViewer);
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        ref={name}
                        style={{color: '#000000', fontSize: '14px', padding: '20px', borderRadius: '126px', border: '1px solid #2A2A2A', width: '100%'}}
                        placeholder={data.name_field}
                        type="text"
                        name="name"
                        required/><br/>
                        {!validateName && <Validation>No name was entered.</Validation>}
                    </div>
                    <div style={{ width: '80%' }}>
                        <Input
                            onChange={(e) => {

                                if (e.target.value.length === 0) {
                                    return setValidateEmail(true)
                                }

                                if (e.target.value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) {
                                    return setValidateEmail(true)
                                }

                                if (!e.target.value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) {
                                    return setValidateEmail(false)
                                }


                            }}
                            ref={email}
                            onBlur={e => {
                                if (window.innerWidth < 500) {
                                    handleSubmit(registerViewer);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter') {
                                    handleSubmit(registerViewer);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            placeholder={data.email_field}
                            type="email"
                            name="email"
                            style={{color: '#000000', fontSize: '14px', padding: '20px', borderRadius: '126px', border: '1px solid #2A2A2A',width: '100%'}}
                            required/><br/>
                    {!validateEmail && <Validation>Invalid email format / no email was entered.</Validation>}
                    </div>
                    <Button style={{
                        border: '1px solid #1D1D1D',
                        background: '#1D1D1D',
                        borderRadius: '87px',
                        outline: 'none',
                        color: 'white',
                        cursor: 'pointer',
                        width: '135px',
                        height: '44px',
                        display: 'inline-block',
                        textTransform: 'uppercase'}} loading={loading} ref={button} type="button" onClick={() => handleSubmit(registerViewer)}>
                            Join
                    </Button>
                </Form>
                {showMessage && (
                    <ThanksMessage>
                        <p><img src="thanks_icon.svg" alt=""/>Thanks for registering for the event. The event has not started yet. Please try to join again after some time.</p>
                        <img alt="close" src="/close-modal.svg" style={{position: 'absolute', right: '20px', top: '20px', width: '10px', cursor: 'pointer'}} onClick={() => handleClose()} />
                    </ThanksMessage>
                )}

        </RegisterContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        registerViewer: (name,email) => dispatch({type: 'REGISTER_VIEWER', payload: {name, email}})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewerModal);
