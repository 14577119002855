import React, {useEffect, useState} from 'react';
import Videos from '../../components/videos/videos';
import './home.css';
import {useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import fetchVideos from '../../store/thunkFunctions';

const useURLParam = () => {
  return new URLSearchParams(useLocation().search);
}

function Home({fetchVideos}) {
  const [status, setStatus] = useState('recorded');
  const [pqd] = [useURLParam().get('pqd')];
  const [logo, setLogo] = useState(null);
  const handleStatus = (vid) => {
    setStatus(vid);
  }

    useEffect(() => {
      async function video() {
          var data = await fetchVideos(pqd);
          data = (await data).video.videos;
          console.log(data)
          setLogo(data[0].attributes.logo);
      }
      if(pqd !== null){
        video();
      }
  },[pqd,fetchVideos])

  return (
    <div className="container">
      {/* iPhone Header */}
      <div className="header-container">
        <div className="header">
          {/* {(logo) && <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} width="150px" height="80px" src={logo} alt="logo" />} */}
          {
            (logo) && <div className="brandLogoWrap" style={{backgroundImage: `url(${logo})`}}></div>
          }
          
          <div className="close-button" onClick={() => {window.parent.postMessage("close",'*')}}>
            <img src="close.svg" alt="close" />
          </div>
        </div>
        <div className="statuses">
            <div onClick={() => handleStatus('recorded')} className={`${status === 'recorded' ? "active" : ""}`}><h4>Past</h4></div>
            <div onClick={() => handleStatus('draft')} className={`${status === 'draft' ? "active" : ""}`}><h4>Upcoming</h4></div>
            <div onClick={() => handleStatus('live')} className={`${status === 'live' ? "activeRed" : ""}`}><h4 style={{color: 'red'}}>Live</h4></div>
        </div>
      </div>
          <Videos status={status} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      state,
      videos: state.videos,
      videoIndex: state.videoIndex
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchVideos: (id) => dispatch(fetchVideos(id)),
      changeIndex: (num) => dispatch({type: 'SELECTED_VIDEO', payload: num})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
