import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
min-height: 30px;
max-width: 98%;
min-width:30%;
margin-bottom: 15px;
-webkit-border-radius:20px;
-webkit-border-top-left-radius: 0;
-moz-border-radius:20px;
-moz-border-radius-topleft: 0;
border-radius:20px;
border-top-left-radius: 0;
background-color: #e1e1e1;
align-items: baseline;
justify-content: flex-start;
display: inline-flex;
clear: both;
float: left;
flex-flow: column-reverse;
flex-direction: column;
`

const Sender = styled.span`
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #000;
`

const Message = styled.span`
    font-size: 12px;
    margin-left: 12px;
    text-align: left;
    padding: 0px 10px 10px 0;
    color: #000;
    word-break: break-all;
`
export default function Comment ({sender, message, senderType, logo}) {
    return (
        <div>
            <Container>
            {(senderType === 'Viewer' || senderType==='Brand')&&<Sender>{sender}: </Sender>}
            {/* {(senderType==='Brand')&&<Sender><img style={{width: 100}} src={logo} alt="logo" /></Sender>} */}
                <Message>{message}</Message>
            </Container>
        </div>
    )
}
