import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
min-height: 30px;
max-width: 100%;
margin-bottom: 15px;
border-radius: 17.5px;
border: solid 1.5px gray;
background-color: white;
align-items: center;
justify-content: flex-start;
display: inline-flex;
clear: both;
float: left;
flex-flow: column-reverse;
flex-direction: row;
padding-top: 5px;
padding-bottom: 5px;
`

const Sender = styled.span`
    margin-left: 10px;
    font-size: 12px;
    font-weight: 700;
`

const Message = styled.span`
    font-size: 12px;
    margin-left: 12px;
    text-align: left;
    padding: 8px 10px 8px 0;
    word-break: break-all;
`
export default function Comment ({sender, message, senderType, logo}) {
    return (
        <div>
            <Container>
                {(senderType === 'Viewer' || (senderType==='Brand'))&&<Sender>{sender}: </Sender>}
                {/* {(senderType==='Brand')&&<Sender><img style={{width: 100}} src={logo} alt="logo" /></Sender>} */}
                <Message>{message}</Message>
            </Container>
        </div>
    )
}