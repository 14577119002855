import React from 'react';
import styles from './CartDesktop.module.css';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

const CartDesktop = ({
  lineItems,
  checkoutTotal,
  currencySign,
  updateCartItem,
  deleteViewerCart,
  removeCartItem,
  viewer_id,
  removeText,
  checkoutText,
  itemRemovedText,
}) => {
  const handleReduceQuantityOnClick = (lineItemId, quantity) => {
    if (quantity === 1) {
      return;
    }

    const newQuantity = quantity - 1;

    updateCartItem({
      line_item_id: lineItemId,
      quantity: newQuantity,
    });
  };

  const handleAddQuantityOnClick = (lineItemId, quantity) => {
    const newQuantity = quantity + 1;

    updateCartItem({
      line_item_id: lineItemId,
      quantity: newQuantity,
    });
  };

  const handleRemoveOnClick = (lineItemId) => {
    removeCartItem(lineItemId)
      .then((res) => {
        toast.success(itemRemovedText);
      })
      .catch((error) => toast.error('Server error.'));
  };

  const handleCheckoutOnClick = () => {
    const shopifyVariantsOnCartWithQuantity = lineItems.map((lineItem) => {
      const str = `${lineItem.variant_shopify_id}:${lineItem.quantity}`;
      return str;
    });

    const checkoutInfoFromPlaybackStr = shopifyVariantsOnCartWithQuantity.join(
      ','
    );

    const foamieCheckoutURL = `https://www.foamie.com/cart/${checkoutInfoFromPlaybackStr}/?utm_source=playback`;

    window.open(foamieCheckoutURL);

    deleteViewerCart(viewer_id)
      .then((res) => {
        toast.success('Successfuly checked out!');
      })
      .catch((error) => toast.error('Server error.'));
  };

  return (
    <>
      {lineItems.length === 0 ? (
        <div className={styles.no_items_in_cart_container}>
          <svg
            className={styles.no_items_cart_icon}
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs></defs>
            <path d="M47.917 7.558H8.884L7.733 0H0v2.93h5.219l6.171 40.7a6.643 6.643 0 0013.117 1.191h3.45a6.644 6.644 0 100-2.93h-3.45a6.639 6.639 0 00-10.941-3.454l-.963-6.354h29.29l3.125-21.6h2.9V7.558zM18.028 39.641a3.715 3.715 0 11-3.715 3.715 3.719 3.719 0 013.715-3.715zm12.694 3.715a3.715 3.715 0 113.711 3.714 3.719 3.719 0 01-3.711-3.714zM9.333 10.488h32.725l-2.7 18.665h-27.2z" />
            <path d="M29.406 14.028l-3.721 3.721-3.721-3.721-2.072 2.072 3.721 3.721-3.721 3.721 2.072 2.072 3.721-3.721 3.721 3.721 2.072-2.072-3.721-3.721 3.721-3.721z" />
          </svg>
          <p className={styles.no_items_cart_text}>No items in cart</p>
        </div>
      ) : (
        <div className={styles.cart_container}>
          <div className={styles.products_container}>
            {lineItems.map((lineItem) => {
              return (
                <div
                  key={lineItem.variant_id.$oid}
                  className={styles.product_details_container}
                >
                  <div className={styles.product_img_container}>
                    <img
                      className={styles.product_img}
                      src={lineItem.image}
                      alt={lineItem.name}
                    />
                  </div>

                  <div className={styles.product_specifics_container}>
                    <div className={styles.product_specifics_top_container}>
                      <span className={styles.product_price}>
                        <NumberFormat
                          value={lineItem.variant_price}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currencySign + ' '}
                        />
                      </span>
                      <div className={styles.remove_button_container}>
                        <button
                          className={styles.remove_btn}
                          type="button"
                          onClick={() => handleRemoveOnClick(lineItem._id.$oid)}
                        >
                          {removeText}
                        </button>
                      </div>
                    </div>

                    <div className={styles.product_specifics_bottom_container}>
                      <p className={styles.product_name}>{lineItem.name}</p>
                    </div>

                    <div className={styles.product_specifics_bottom_container}>
                      <div className={styles.product_qty_controller_container}>
                        <button
                          className={styles.product_qty_ctrl_btn}
                          style={
                            lineItem.quantity === 1
                              ? { visibility: 'hidden' }
                              : { visibility: 'visible' }
                          }
                          type="button"
                          onClick={() =>
                            handleReduceQuantityOnClick(
                              lineItem._id.$oid,
                              lineItem.quantity
                            )
                          }
                        >
                          <svg
                            className={styles.minus_icon}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>

                        <div className={styles.product_qty_container}>
                          <span className={styles.product_qty}>
                            {lineItem.quantity}
                          </span>
                        </div>

                        <button
                          className={styles.product_qty_ctrl_btn}
                          type="button"
                          onClick={() =>
                            handleAddQuantityOnClick(
                              lineItem._id.$oid,
                              lineItem.quantity
                            )
                          }
                        >
                          <span className={styles.plus_icon}>+</span>
                        </button>
                      </div>

                      <span className={styles.total_price_per_product}>
                        <NumberFormat
                          value={lineItem.item_total}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currencySign + ' '}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.checkout_details}>
            <div className={styles.total_details_container}>
              <span className={styles.total_text}>Total</span>
              <span className={styles.total_float}>
                <NumberFormat
                  value={checkoutTotal}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={currencySign + ' '}
                />
              </span>
            </div>
            <div className={styles.checkout_btn_container}>
              <button
                className={styles.checkout_btn}
                type="button"
                onClick={handleCheckoutOnClick}
              >
                {checkoutText}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartDesktop;
