/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import ReactPlayer from 'react-player';
import firebase from '../../firebase';
import Comment from '../../components/comment-mobile';
import { toast } from 'react-toastify'
import ContextProduct from '../../components/products/contextual-product';
import './play-mobile.css';
import { API_BASE_URL, headers } from '../../constants';
import { connect } from 'react-redux';
import fetchVideos, { updateCartItem, addProductToCart, viewerCart, deleteViewerCart, removeCartItem } from '../../store/thunkFunctions';
import {getCurrencySign} from '../../helpers';
import BannerURL from "./bannerUrl.png";
import moment from 'moment';


const useURLParam = () => {
    return new URLSearchParams(useLocation().search);
}

const CheckoutCart = ({ checkout_btn, item_removed_msg, remove_button_text, setShowProducts, delete_viewer_cart, viewer_id, currency, update_cart_item, total, remove_cart_item, setProduct, setShowCart, setPreventRenderProducts, line_items }) => {
    const [checkedOut, setCheckedOut] = useState(false)

    const checkOutHandler = () => {
        const cartQuery = line_items.map(item => {
            return `${item.variant_shopify_id}:${item.quantity}`
        }).join(',')
        window.open(`https://www.foamie.com/cart/${cartQuery}?utm_source=playback`)
        delete_viewer_cart(viewer_id).then(res => {
            setCheckedOut(true)
        })
    }

    if (line_items.length === 0 && !checkedOut) {
        return (<div className="product-to-cart">
        <div className="back-to-products-header" onClick={() => {
            setShowCart(false);
            setShowProducts(false);
            setProduct(null);
            setPreventRenderProducts(false);
        }}>
            <img id="close-checkout" src="close-modal.svg" alt="" />
            <span>Close Checkout</span>
        </div>
        <div className="cart-no-items">
            <div className="no-items-container">
                <img src="remove-from-cart.svg" alt="" />
                <h3>No items added to cart.</h3>
            </div>
        </div>
    </div>)
    }

    if (checkedOut) {
        return(
            <div className="product-to-cart">
        <div className="back-to-products-header" onClick={() => {
            setShowCart(false);
            setProduct(null);
            setPreventRenderProducts(false);
        }}>
            <img id="close-checkout" src="close-modal.svg" alt="" />
            <span>Close Checkout</span>
        </div>
        <div className="cart-no-items">
            <div className="no-items-container">
                <img src="item_success.svg" alt="" />
                <h3>You checked out!</h3>
            </div>
        </div>
    </div>
        )
    }

    return (
        <div className="checkout-cart">
            <div className="checkout-cart-header" onClick={() => {
                setShowCart(false);
                setProduct(null);
                setPreventRenderProducts(false);
            }}>
                <img id="close-checkout" src="close-modal.svg" alt="" />
                <span>Close Checkout</span>
            </div>
            <div className="checkout-items">
                {
                    line_items.map(item => {
                        return (
                    <div className="checkout-item" key={item.variant_id.$oid}>
                        <img className="checkout-item-image" src={item.image} alt="" />
                        <div className="checkout-item-content">
                            <div className="checkout-item-content-header">
                                <span>{currency} {item.variant_price}</span>
                                <button onClick={() => remove_cart_item(item._id.$oid).then(res => toast.success(item_removed_msg))}>{remove_button_text}</button>
                            </div>
                            <div className="checkout-item-content-body">
                                <span>{item.name}</span>
                            </div>
                            <div className="checkout-item-content-footer">
                                <div className="checkout-item-content-quantity-controller">
                                    <button onClick={() => {
                                        const line_item_id = item._id.$oid
                                        const quantity = item.quantity - 1;

                                        update_cart_item({
                                            line_item_id,
                                            quantity
                                        })

                                    }} style={ (item.quantity === 1) ? { opacity: 0 } : null}>-</button>
                                    <span>{item.quantity}</span>
                                    <button onClick={() => {
                                        const line_item_id = item._id.$oid
                                        const quantity = item.quantity + 1;

                                        update_cart_item({
                                            line_item_id,
                                            quantity
                                        })

                                    }}>+</button>
                                </div>
                                <div>
                                    <span>{currency} {item.item_total}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                        )
                    })
                }
            </div>
            <div className="checkout-cart-footer">
                <div className="checkout-total">
                    <span>Total</span>
                    <span>{currency} {total}</span>
                </div>
                <button onClick={checkOutHandler} className="checkout-btn">{checkout_btn}</button>
            </div>
        </div>
    )
}

const ProductToCart = ({ item_added_msg, back_to_products_text, add_to_cart_text, currency, add_product_to_cart, product, viewer_id, setProduct}) => {
    const [img, setImg] = useState(null)
    const [variantToCart, setVariantToCart] = useState(null)
    const variantHandler = e => {
        const variant = product.attributes.variants.find(variant => variant.product_id.$oid === e.target.value)
        setImg(variant.img)
        setVariantToCart({
            variant_id: variant._id.$oid,
            quantity: 1,
            viewer_id
        })
    }

    if (!product) {
        return null
    }

    return(
        <div className="product-to-cart">
            <div className="back-to-products-header" onClick={() => setProduct(null)}>
                <img id="back-to-products" src="next.svg" alt="" />
                <span>{back_to_products_text}</span>
            </div>
            <div className="product-to-cart-content">
                <img className="product-to-cart-image" src={ img || product.attributes.images[0]} alt="" />
                <div className="product-to-cart-details">
                    <span>{`${currency} ${product.attributes.price}`}</span>
                    <span>{product.attributes.name}</span>
                </div>
                {
                    product.attributes.variants.length > 2 && (
                        <select className="product-to-cart-variants-select" onChange={variantHandler}>
                            <option defaultValue={null}>Select</option>
                            {
                                product.attributes.variants.map(variant => {
                                    return (
                                        <option value={variant.product_id.$oid} key={variant._id.$oid}>
                                            {variant.option1 ? `${variant.option1} ` : null }
                                            {variant.option2 ? `/ ${variant.option2}` : null}
                                            {variant.option3 ? `/ ${variant.option3}` : null}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    )
                }
                <button style={product.attributes.variants.length > 2 ? { marginTop: '1.5625rem' } : null} onClick={() => {
                    if (variantToCart !== null) {
                        add_product_to_cart(variantToCart).then(res => {
                            if(res) {
                                return toast.success('Item added to cart')
                            }

                            return toast.error('Server Error')
                        })
                    } else {
                        add_product_to_cart({
                            variant_id: product.attributes.variants[0]._id.$oid,
                            quantity: 1,
                            viewer_id
                        }).then(res => {
                            if (res) {
                                return toast.success(item_added_msg)
                            }
                            return toast.error('Server Error')
                        })
                    }
                    setVariantToCart(null)
                }}>
                    {add_to_cart_text}
                </button>
            </div>
        </div>
    )
}

const RenderProducts = ({products, currentVideo, setProduct, currency}) => {
    return (
        <>
            {
                products?.map(product => {
                    return(
                        <div className="product-container" key={product.attributes.id.$oid}>
                            <img className="product-image" src={product.attributes.images[0]} alt=""
                                onClick={() => {
                                    if (currentVideo.attributes.integrated_checkout) {
                                        setProduct(product)
                                    } else {
                                        window.open(product.attributes.product_page_url)
                                    }
                                }}
                            />
                            <div className="product-description" onClick={() => {
                                if (currentVideo.attributes.integrated_checkout) {
                                    setProduct(product)
                                } else {
                                    window.open(product.attributes.product_page_url)
                                }
                            }}>
                                <span>{`${currency} ${product.attributes.price}`}</span>
                                <span>{product.attributes.name}</span>
                            </div>
                            <img className="product-arrow" src="next.svg" alt="" onClick={() => {
                                if (currentVideo.attributes.integrated_checkout) {
                                    setProduct(product)
                                } else {
                                    window.open(product.attributes.product_page_url)
                                }
                            }} />
                        </div>
                    )
                })
            }
        </>
    )
}

function PlayMobile({ frameSecond, updateFrameSecond, update_cart_item, total, remove_cart_item, delete_viewer_cart, set_viewer_cart, fetchVideos, viewer, viewer_id, set_viewer_id, add_product_to_cart, line_items}) {
    const [pqd,] = useState(useURLParam().get('pqd'));
    const [pgpqvid,] = useState(useURLParam().get('pgpqvid'));
    const [videoProps, setVideoProps] = useState({opacity: 0, playing: false, controls: false, muted:true});
    const [pausePlay, setPausePlay] = useState(false);
    const [paused, setPaused] = useState(false)
    const [showProducts, setShowProducts] = useState(true);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isFetching, setFetching] = useState(true);
    const [comments, setComments] = useState([]);
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState(false);
    const [playVideo, setPlay] = useState(false);
    const [productURL, setProductURL] = useState(null);
    const [contextProducts, setContextProducts] = useState(null);
    const [streamStatus, setStreamStatus] = useState(true);
    const [product, setProduct] = useState(null)
    const [products, setProducts] = useState([])
    const livePlayer = useRef()
    const [showCart, setShowCart] = useState(false)
    const [preventRenderProducts, setPreventRenderProducts] = useState(false)
    const [page, setPage] = useState(30)

    useEffect(() => {
        async function video() {
            const res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}.json`, {
                method: 'GET',
                headers
              });
              setSelectedVideo((await res.json()).data);
              setFetching(false);
              return null;
          }
        video();
    },[])

    const handleCommentScroller = () => {
        const element = document.getElementById('comment-section');
        if(element) element.scrollTop = element.scrollHeight;
    }

    const fetchChat = async () => {
        const commentsRef = firebase.database().ref('comments').orderByChild('video_id').equalTo(pgpqvid).limitToLast(30)
        let sortedMessages = [];

        commentsRef.on('child_added', (data) => {
            sortedMessages.push(data.val());
            let currentComments = [];
            for (let item of sortedMessages) {
                currentComments.push(item);
            }
            setComments(currentComments);
            setTimeout(() => {handleCommentScroller()},0)
            console.log(currentComments);
        });
    }

    const fetchRealTimeContent = async () => {
        const contentsRef = firebase.database().ref('contextual_contents').orderByChild('video_id').equalTo(pgpqvid);
        let sortedArray = [];
        contentsRef.on('child_added', (data) => {
            sortedArray.push(data.val());
            sortedArray = sortedArray.slice(Math.max(sortedArray.length - 3, 0));
            sortedArray.sort(function(a,b) {
              return a.frame_second - b.frame_second;
            });
            console.log("the array is", sortedArray)
            setContextProducts(sortedArray);
          });
          contentsRef.on('child_changed', (data) => {
            sortedArray = sortedArray.filter(
              item => item._id !== data.val()._id
            );
            setContextProducts(sortedArray);
          });
    }

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },[])

    useEffect(() => {
        (async () => {await fetchChat()})();
        (async () => {await fetchRealTimeContent()})();
        (async () => {
            let res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}/products.json`, {
                method: 'GET',
                headers
            })
            res = (await res.json()).data
            setProducts(res)
        })();
        (async () => {
            let res = await viewerCart(viewer_id)
            set_viewer_cart({
                lineItems: res?.attributes.line_items || [],
                total: res?.attributes.net_total || []
            })
        })();

        return () => setContextProducts(null);
    }, [pgpqvid, viewer_id, page])

    useEffect(() => {
        async function sendLeads() {
            const res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}/viewers.json`, {
                method: 'POST',
                body: JSON.stringify({
                    email: viewer.email,
                    name: viewer.name
                }),
                headers
            })
            const id_viewer = (await res.json()).data;
            if (viewer_id === null) {
                set_viewer_id(id_viewer.id)
            }
        }
        if (viewer !== null) {
            sendLeads();
        }

    }, [viewer, pgpqvid])

    const RenderComments = () => {
        if (!comments) {
            return null
        }else {
            return (<>
                {comments.map((comment) => <Comment key={comment.id} sender={comment.sender_name}
                    senderType={comment.sender_klass}
                    logo={comment.logo}
                    message={comment.body}
                />)}
            </>)
        }
    }

    const handleVideo = () => {
        if (selectedVideo.attributes.products.length === 0) {
            setShowProducts(false);
            setPreventRenderProducts(true)
        }
    }

    const handleLiveViewer = () => {
        async function sendLeads() {
            const res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}/viewers.json`, {
                method: 'POST',
                body: JSON.stringify({
                    email: viewer.email,
                    name: viewer.name
                }),
                headers
            })
            console.log(res)
        }
        if (viewer !== null) {
            sendLeads();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = `${API_BASE_URL}/videos/${pgpqvid}/comments.json`;
        messageRef.current.value = '';
        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                sender_name: viewer.name,
                sender_email: viewer.email,
                body: message,
              }),
            headers
          })
        setMessage('');
        console.log(response);
        handleCommentScroller();

    }
    const history = useHistory();


    const handleVideoClicks = async (viewer_id, product_id) => {
        const res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}/video_clicks.json`, {
            method: 'POST',
            body: JSON.stringify({
                viewer_id,
                product_id
            }),
            headers
        })

        const data = (await res.json()).data;
        console.log(data);
    }

    // window.parent.postMessage("close",'*')
    const messageRef = React.createRef();

    useEffect(() => {window.addEventListener('message', (e) => {
        if (e.data === 'close') {
           setProductURL(null);
        }
    })}, [])

    const checkScheduleHandler = () => {
        async function video() {
            const res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}.json`, {
                method: 'GET',
                headers
              });
            const currentVideo = (await res.json()).data
            if (currentVideo.attributes.status === 'live' || currentVideo.attributes.status === 'recorded') {
                setSelectedVideo(currentVideo);
                setFetching(false);

                return toast.success(`${ currentVideo.attributes.status === 'live' ? "Event has started! Please click the play logo again." : "The event is now recorded."}`)
            }
                return toast.error("The event has not started yet.")
          }
        video();
    }

    const RenderContextProductRecorded = ({time}) => {
        var ContextProducts = contextProducts.filter((product) => {
            if (product.frame_second_exit === undefined) {
                return (((time >= product.frame_second_enter)))
            } else {
                return ((time >= product.frame_second_enter) && (time <= product.frame_second_exit))
            }
        });

        return (ContextProducts)? (<>
            {
                ContextProducts.map((product) => {
                    return (
                        <div key={product.id}>
                            <ContextProduct image_url={product.image_url}
                                currency={getCurrencySign(selectedVideo.attributes.currency)}
                                key={product.id}
                                description={product.description}
                                goTo={() => {handleVideoClicks(viewer_id, product.id);
                                    if (selectedVideo.attributes.integrated_checkout) {
                                        setProduct(products.filter(prod => prod._id.$oid === product.id))
                                    } else {
                                        window.open(product.target_url)
                                    }
                                }}
                            />
                        </div>
                    )
                })
            }
        </>):null
    }

    const RenderContextProductLive = () => {
        return (contextProducts)? (<>
        {
            contextProducts.map((product) => <ContextProduct image_url={product.image_url}
            key={product.id}
            goTo={() => {handleVideoClicks(viewer_id, product.id);
                if (selectedVideo.attributes.integrated_checkout) {
                    setProduct(products.filter(prod => prod._id.$oid === product.id))
                } else {
                    window.open(product.target_url)
                }
            }}
            description={product.description}
        />)
        }
        </>):null
    }

    const streamEndedHandler = () => {
        var stream = setTimeout(() => {
                fetchVideos(pqd).then((res) => {
                    var toPlayVideo = res.video.videos.filter((video) => video.id === pgpqvid)[0]
                    if(toPlayVideo.attributes.status === 'recorded'){
                        setStreamStatus(false)
                        clearTimeout(stream);
                    }
                });
            }, 800);
        setPlay(false)
    }

    useEffect(() => {
        if (streamStatus === false) {
            setTimeout(() => history.push(`/?pqd=${pqd}`),2000)
        }

    }, [streamStatus])

    useEffect(() => {
        if (selectedVideo && (selectedVideo.attributes.status === 'draft' || selectedVideo.attributes.status === 'upcoming')) {
            console.log(moment(selectedVideo.attributes.scheduled_time).local().format('DD MMMM Y HH:mmA'))
        }
    },[selectedVideo, pgpqvid])

    const ProductFrame = () => {
        if (productURL) {
            return (
                <div className="product-page" style={{transition: '0.5s'}}>
                    <div style={{width: '100%', height:'10%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: 'solid 1px #6b6b6b'}}>
                        <h4 style={{marginLeft: 10}}>Back to Products</h4>
                        <img onClick={() => setProductURL(null)} style={{marginRight: 20}} src="close-modal.svg" alt="close iframe" />
                    </div>
                    <iframe style={{border: 0, overflow: 'hidden', overflowY: 'auto'}} width="100%" height="90%" src={productURL} title="product page"></iframe>
                </div>
            )
        } else {
            return null;
        }
    }

    return(
        <div className="play-shop-container-mobile" style={{position: 'relative'}}>
            {(viewer) && <>
                {(!(videoProps.playing)) && isFetching && (<div className="loading">
                    <img style={{display: 'block'}} src="loading.svg" alt="loading"/>
                    <h3 style={{color: '#6b6b6b'}}>Please wait for a moment....</h3>
                </div>)}
            {(!isFetching && ((selectedVideo.attributes.status === 'recorded'))) && <ReactPlayer
                className="react-player-mobile"
                onProgress={(state) => {updateFrameSecond(state.playedSeconds);}}
                id="mobile-player"
                url={`${selectedVideo.attributes.livestream_url}#t=0.1`}
                width='100%'
                height='100%'
                ref={livePlayer}
                playsinline={true}
                style={{background: `url(${BannerURL}) no-repeat center center`, backgroundSize: 'cover'}}
                controls={false}
                onReady={(val)=>{
                    sessionStorage.removeItem('intendedRoute')
                    handleVideo();
                }}
                onEnded={() => {setPlay(true); setVideoProps({...videoProps, playing: false})}}
                onError={(err) => {console.log(err); setVideoProps({...videoProps, playing: true})}}
                stopOnUnmount={true}
                onStart={() => {setPlay(false); setVideoProps({...videoProps, muted: false})}}
                light={false}
                config={{
                    file: {
                        attributes: {
                            preload: 'metadata',
                            onCanPlay: () => {
                                setPlay(true)
                                console.log(selectedVideo.attributes)
                            }
                        }
                    }
                }}
            />}

            {(!isFetching && (streamStatus) && (selectedVideo.attributes.status === 'live')) && <ReactPlayer
                className="react-player-mobile"
                id="mobile-player"
                url={selectedVideo.attributes.livestream_url}
                width='100%'
                height='100%'
                ref={livePlayer}
                playsinline={true}
                style={{background: `url(${BannerURL}) no-repeat center center`, backgroundSize: 'none'}}
                playing={false}
                controls={false}
                onReady={()=>{
                    sessionStorage.removeItem('intendedRoute')
                    handleVideo()
                    if(viewer) setPlay(true)
                    handleLiveViewer();
                }}
                onBuffer={() => {streamEndedHandler()}}
                onProgress={() => {
                }}
                stopOnUnmount={true}
                onStart={() => {setPlay(false); setVideoProps({...videoProps, muted: false})}}
                onEnded={(data) => console.log(data)}
                onError={(err) => {console.log(err); setVideoProps({...videoProps, playing: true})}}
                light={false}
                config={{
                    file: {
                        attributes: {
                            preload: 'none',
                            poster: BannerURL,
                            style: {
                                objectFit: 'cover !important',
                                width: '100%',
                                height: '100%'
                            }
                        }
                    }
                }}
            />}

            {(!isFetching && ((selectedVideo.attributes.status === 'upcoming') || (selectedVideo.attributes.status === 'draft')) && (viewer)) && (
                <div className="upcoming-container" style={{background: `url(${BannerURL}) no-repeat center center`, backgroundSize: 'cover'}}>
                    <div>
                        <div className="upcoming-logo-container" onClick={checkScheduleHandler}>
                            <img src="play.svg" alt="play" />
                        </div>
                    </div>
                    <div style={{textAlign: 'center', width: '100%', position: 'absolute', zIndex: 5, bottom: '55%' }}>
                            <h5>{selectedVideo.attributes.event_upcoming_title_text}</h5>
                            <h3>{moment(selectedVideo.attributes.scheduled_time).local().format('MMMM DD, Y h:mmA')}</h3>
                    </div>
                </div>
            )}

            {(!isFetching && (!streamStatus) && (viewer)) && (
                <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                    <img style={{width: '100%', height: '100%'}} src={selectedVideo.attributes.banner_url} alt="banner" />
                    <div style={{borderRadius: 20, paddingTop: '10%', paddingBottom: '10%', textAlign: 'center', color: 'white', top: '30%', left: 0,width: '100%', backgroundColor: 'rgba(0,0,0,0.7)', position: 'absolute', zIndex: 3}}>
                        <div>
                            <img style={{width: '50%', height: '20%'}} src={selectedVideo.attributes.logo} alt="brand logo"/>
                        </div>
                        <h4>The stream has ended. Thank you for tuning in!</h4>
                        <h5>You will be redirected to videos page in a few seconds....</h5>
                    </div>
                </div>
            )}

                {(videoProps.playing || !videoProps.playing) && (
                <>
                <div className="context-container-mobile" style={{width: '30%', position: 'absolute', zIndex: 5, top: 0, left: 0}}>
                    <div className="left-arrow" onClick={() => history.push(`/?pqd=${pqd}`)}>
                        <img src="left-arrow.svg" alt="shop-arrow"/>
                    </div>
                    {(!isFetching)&&(contextProducts) && (selectedVideo.attributes.status === 'recorded') && <RenderContextProductRecorded time={frameSecond} />}
                    {(!isFetching)&&(contextProducts) && (selectedVideo.attributes.status === 'live') && <RenderContextProductLive />}
                </div>

                {
                    (!isFetching) && (selectedVideo.attributes.status === 'recorded') && pausePlay && (
                        <div className="play-pause" onClick={() => {
                            if (paused) {
                                livePlayer.current.getInternalPlayer().play()
                            } else {
                                livePlayer.current.getInternalPlayer().pause()
                            }
                            setPaused(!paused)
                        }}>
                            <img src={paused ? "play-mobile-logo.svg" : "pause-mobile-logo.svg"} alt="pause-play logo" />
                        </div>
                    )
                }

                {
                    !showCart && !isFetching && selectedVideo?.attributes.integrated_checkout && (
                        <div className="cart-container" onClick={() => {
                            // if (!showCart && showProducts) {
                            //     setShowCart(!showCart);
                            //     setShowProducts(false);
                            //     setPreventRenderProducts(true)}
                            // }
                            if (!showCart && !product) {
                                setShowCart(!showCart);
                                if (chat) {
                                    setChat(!chat);
                                }
                                setPreventRenderProducts(true)
                            } else {
                                setProduct(null)
                                setShowCart(!showCart)
                                setPreventRenderProducts(true)
                            }
                            }
                        }>
                            <img src="shopping-cart-white.svg" alt="shopping-cart" />
                            <span>{line_items.length}</span>
                        </div>
                    )
                }
                <div className="close-button-mobile" onClick={() => window.parent.postMessage("close",'*')}>
                    <img src="close.svg" alt="close" />
                </div>
                </>)}

            {/* {(!isFetching) && (
                <>

                   {(currentIndex !== 0)&&(
                                <div id="previous-container" onClick={() => previousHandler()}>
                                    <div className="next" id="previous">
                                        <img src="next.svg" alt="previous"/>
                                    </div>
                                </div>
                   )
                    }

                    {(currentIndex !== (videos.length-1))&&(<div id="next-container" onClick={() => nextHandler()}>
                        <div className="next" id="next">
                            <img src="next.svg" alt="previous"/>
                        </div>
                    </div>)
                    }
                </>
            )} */}
            {(!isFetching && playVideo) && ((selectedVideo.attributes.status === 'recorded') || (selectedVideo.attributes.status === 'live')) &&
                (<>
                    <div className="logo-container" onClick={() => {livePlayer.current.getInternalPlayer().play(); setPausePlay(true); livePlayer.current.getInternalPlayer().removeAttribute('poster'); setPlay(false); setTimeout(() => setVideoProps({...videoProps, playing: true}), 1000)}}>
                        <img src="play.svg" alt="play" />
                    </div>
                    <div className="recorded-live-message" style={{textAlign: 'center', width: '100%' }}>
                        <h5>{selectedVideo.attributes.status === 'recorded' ? selectedVideo.attributes.event_recorded_title_text : selectedVideo.attributes.event_live_title_text}</h5>
                        <h3>{moment(selectedVideo.attributes.created_at).local().format('MMMM DD, h:mmA')}</h3>
                    </div>
                </>
                )
            }
            {(selectedVideo) && (<div className={`${(selectedVideo.attributes.status === 'draft' || selectedVideo.attributes.status === 'upcoming') ? "shop-mobile no-product-to-cart" : "shop-mobile"} ${ (product) ? "with-product-to-cart" : (showCart ? "with-cart-checkout" : (chat ? "no-product-to-cart" : ((selectedVideo.attributes.status === 'draft' || selectedVideo.attributes.status === 'upcoming') && !showProducts ? "" : "no-product-to-cart")))}`} style={ (showProducts || showCart)? { zIndex: 6 } : null}>
             <div className={`shop-mobile-parent-container ${ (showProducts) ? "shop-mobile-main-container-2" : ( showCart ? "shop-mobile-main-container-2" : ( chat ? "shop-mobile-main-chat-container": (!chat ? "shop-mobile-main-chat-exit-container" : "shop-mobile-main-container")))}`} >
                 {
                     !showCart && (
                        <div style={{display: 'flex', justifyContent: 'space-between', height: '10%'}}>
                        <div style={{display: 'flex', alignItems: 'center'}} onClick={() => {setShowProducts(!showProducts); setChat(false);
                            setPreventRenderProducts(false);
                            setTimeout(() => setProduct(null), 50)
                        }}>
                            <h1 style={{marginLeft: 14, fontSize: 18, color: 'white'}} >{selectedVideo.attributes.shop_label}</h1>
                            <img className={"shop-arrow " + (showProducts? "shop-down" : null)} src="left-arrow.svg" alt="left-arrow" />
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', marginRight: 14}} onClick={() => {setChat(!chat); setShowProducts(false); setPreventRenderProducts(true); setProduct(null); setShowCart(false); setTimeout(() => handleCommentScroller(), 500)}}>
                            <h1 style={{marginLeft: 14, fontSize: 18, color: 'white'}} >{selectedVideo.attributes.comment_label}</h1>
                            <img className={"shop-arrow " + (chat ? "shop-down" : null)} src="left-arrow.svg" alt="left-arrow" />
                        </div>
                     </div>
                     )
                 }

                    { (chat) && !showProducts && (
                     <div className="chat-container-mobile" >
                         {
                             (comments.length >= 30) && (
                                <p
                                style={{
                                   textAlign: "center",
                                   color: "#000",
                                   padding:"5px 0px"
                               }}
                                onClick={() => setPage(page => page + 30)}
                            >Load More</p>
                             )
                         }
                     <div className="comment-section-mobile" id="comment-section-mobile">
                         <div id="comment-section" style={{position: 'absolute',bottom: 0,zIndex: 8,overflow: 'hidden',overflowY: 'auto',width: '100%',height: '100%'}}>
                         { <RenderComments /> }
                         </div>
                     </div>
                     <form className="comment-input-container-mobile" onSubmit={handleSubmit}>
                         <input ref={messageRef} onBlur={handleSubmit} onChange={() => setMessage(messageRef.current.value)} type="text" placeholder={selectedVideo.attributes.comment_text} />
                         <input type="image" name="submit" src="sendmobile.svg" alt="Submit" />
                     </form>
                  </div>
                 )}

                {(!isFetching && (selectedVideo !== null)) && !product && !preventRenderProducts && !chat  && showProducts &&  (
                    <div className="shop-container">
                        <div className="shop">
                            {
                                selectedVideo.attributes.products.length > 0 && <RenderProducts currency={getCurrencySign(selectedVideo.attributes.currency)} setProduct={setProduct} currentVideo={selectedVideo} products={products} />
                            }
                        </div>
                    </div>
                )}
                {
                    product && !showCart && !chat && selectedVideo?.attributes.integrated_checkout && <ProductToCart item_added_msg={selectedVideo.attributes.item_added_msg} add_to_cart_text={selectedVideo.attributes.add_to_cart_text} back_to_products_text={selectedVideo.attributes.back_to_products_text} currency={getCurrencySign(selectedVideo.attributes.currency)} product={product} add_product_to_cart={add_product_to_cart} viewer_id={viewer_id} setProduct={setProduct} />
                }
                {
                    preventRenderProducts && !chat && selectedVideo?.attributes.integrated_checkout && showCart && (
                        <CheckoutCart checkout_btn={selectedVideo.attributes.checkout_btn} item_removed_msg={selectedVideo.attributes.item_removed_msg} remove_button_text={selectedVideo.attributes.remove_button_text} setShowProducts={setShowProducts} viewer_id={viewer_id} currency={getCurrencySign(selectedVideo.attributes.currency)} delete_viewer_cart={delete_viewer_cart} update_cart_item={update_cart_item} total={total} remove_cart_item={remove_cart_item} line_items={line_items} setProduct={setProduct} setShowCart={setShowCart} setPreventRenderProducts={setPreventRenderProducts} />
                    )
                }
             </div>
            </div>)}
            </>}
            {ProductFrame()}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        videoList: state.video.videos,
        frameSecond: state.video.frameSecond,
        viewer: state.auth.viewer,
        viewer_id: state.auth.viewer_id,
        line_items: state.cart.lineItems,
        total: state.cart.total
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideos: (id) => dispatch(fetchVideos(id)),
        updateFrameSecond: (frame) => dispatch({type: 'FRAME_SECOND', payload: frame}),
        set_viewer_id: (id) => dispatch({type: 'SET_VIEWER_ID', payload: id}),
        set_viewer_cart: (cart) => dispatch({type: 'ADD_TO_CART', payload: cart}),
        add_product_to_cart: ({variant_id, quantity, viewer_id}) => dispatch(addProductToCart({variant_id, quantity, viewer_id})),
        delete_viewer_cart: (viewer_id) => dispatch(deleteViewerCart(viewer_id)),
        remove_cart_item: (line_item_id) => dispatch(removeCartItem(line_item_id)),
        update_cart_item: ({line_item_id, quantity}) => dispatch(updateCartItem({line_item_id, quantity}))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayMobile));
