const initialState = {
    videos: [],
    videoIndex: null,
    frameSecond: 0
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case 'FETCH_VIDEOS':
            return {...state, videos: [...action.payload]}
        case 'SELECTED_VIDEO':
            return {...state, videoIndex: action.payload}
        case 'FRAME_SECOND':
            return {...state, frameSecond: action.payload}
        
        default:
            return state
    }
}

export default reducer