/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import PlayDesktop from './desktop/play-desktop';
import PlayMobile from './play-mobile';
import {connect} from 'react-redux';
import fetchVideos from '../../store/thunkFunctions';
import './registerViewer.css';
import { API_BASE_URL, headers } from '../../constants';


const useURLParam = () => {
    return new URLSearchParams(useLocation().search);
}

function Play({fetchVideos, isAuthenticated, set_pqd, pqd_state}) {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [pqd,] = useState(useURLParam().get('pqd'));
    const [pgpqvid,] = useState(useURLParam().get('pgpqvid'));
    const [logo, setLogo] = useState(null);
    const history = useHistory();

    useEffect(() => {
        async function video() {
            const res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}.json`, {
                method: 'GET',
                headers
              });
            if (!res) {
                history.push(`/?pqd=${pqd}`)
            }
            await setLogo((await res.json()).data.attributes);
            return null;
          }
        video();
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pqd,pgpqvid,fetchVideos])

    useEffect(() => {
        function handleResize () {
            setInnerWidth(window.innerWidth);
        }

        window.addEventListener('resize', () => handleResize());

        return () => window.removeEventListener('resize', () => handleResize());
    }, []);

    useEffect(() => {
        set_pqd(pqd)
        sessionStorage.setItem('intendedRoute', `?pqd=${pqd}&pgpqvid=${pgpqvid}`);
    }, [pqd,pgpqvid, set_pqd])

    useEffect(() => {
        if (logo && !isAuthenticated) {
            history.push({
                pathname: '/register',
                state: { currentVideo: logo, setPQD: `?pqd=${pqd_state}` }
            })
        }
    }, [logo, isAuthenticated])
    
    if(isAuthenticated) {
        if(innerWidth <= 768) {
            return <PlayMobile />
        }
        return <PlayDesktop />
    }

    return null
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        viewer: state.auth.viewer,
        pqd_state: state.auth.pqd,
        intendedRoute: state.video.intendedRoute,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideos: (id) => dispatch(fetchVideos(id)),
        set_pqd: (id) => dispatch({type: 'SET_PQD', payload: id}),
        set_intended_route: (path) => dispatch({type: 'SET_INTENDED_ROUTE', payload: path})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Play);
