import React, { useEffect, useState, useRef, memo } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import {
  API_BASE_URL,
  headers,
  DRAFT,
  UPCOMING,
  LIVE,
  RECORDED,
} from '../../../constants';
import ReactPlayer from 'react-player';
import { getCurrencySign } from '../../../helpers';
import firebase from '../../../firebase';
import moment from 'moment';
import { toast } from 'react-toastify';

// Components
import Comment from '../../../components/comment';
import ProductContainer from '../../../components/products/product-container';
import IntegratedProductsContainer from '../../../components/products/IntegratedProductsContainer';
import IntegratedProductDetailsPage from '../../../components/products/IntegratedProductDetailsPage';
// import VideoContainer from '../../../components/otherVideo';
import ContextProductWeb from '../../../components/products/ContextProductWeb';
import CartDesktop from './CartDesktop';
import './play-desktop.css';
import BannerURL from '../bannerUrl.png';

// Redux related
import { connect } from 'react-redux';
import fetchVideos, {
  viewerCart,
  updateCartItem,
  deleteViewerCart,
  addProductToCart,
  removeCartItem,
} from '../../../store/thunkFunctions';

const useURLParam = () => {
  return new URLSearchParams(useLocation().search);
};

function PlayDesktop({
  frameSecond,
  updateFrameSecond,
  fetchVideos,
  viewer,
  history,
  viewer_id,
  set_viewer_id,
  set_viewer_cart,
  cart,
  updateCartItem,
  addProductToCart,
  deleteViewerCart,
  removeCartItem,
}) {
  const [pqd] = [useURLParam().get('pqd')];
  // eslint-disable-next-line no-unused-vars
  const [pgpqvid, setPGPQVID] = useState(useURLParam().get('pgpqvid'));
  const [videoProps, setVideoProps] = useState({
    opacity: 0,
    playing: false,
    controls: false,
    muted: false,
  });
  const [setPlay, setPlayVideo] = useState(false)
  const [isFetching, setFetching] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isIntegratedCheckout, setIsIntegratedCheckout] = useState(false);
  const [isViewingCart, setIsViewingCart] = useState(false);
  const [isViewingIntegratedProduct, setIsViewingIntegratedProduct] = useState(
    false
  );
  const [selectedIntegratedProduct, setSelectedIntegratedProduct] = useState(
    {}
  );
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState('');
  const [streamStatus, setStreamStatus] = useState(true);
  const [contextProducts, setContextProducts] = useState([]);
  const [products, setProducts] = useState({});
  const [
    isPlayButtonClickedForTheFirstTime,
    setIsPlayButtonClickedForTheFirstTime,
  ] = useState(false);
  const [page, setPage] = useState(30);

  useEffect(() => {
    async function video() {
      const res = await fetch(`${API_BASE_URL}/videos/${pgpqvid}.json`, {
        method: 'GET',
        headers
      });
      setSelectedVideo((await res.json()).data);
      setFetching(false);
      return null;
    }
    video();
  }, [fetchVideos, pqd, pgpqvid]);

  useEffect(() => {
    function checkSelectedVideoIntegratedCheckout() {
      if (selectedVideo) {
        if (selectedVideo.attributes.integrated_checkout === true) {
          setIsIntegratedCheckout(true);
        } else {
          setIsIntegratedCheckout(false);
        }
      }
    }

    checkSelectedVideoIntegratedCheckout();
  }, [selectedVideo]);

  useEffect(() => {
    const fetchChat = async () => {
      const commentsRef = firebase
        .database()
        .ref('comments')
        .orderByChild('video_id')
        .equalTo(pgpqvid)
        .limitToLast(page);

      let sortedMessages = [];

      commentsRef.on('child_added', (data) => {
        sortedMessages.push(data.val());

        let currentComments = [];

        for (let item of sortedMessages) {
          currentComments.push(item);
        }

        setComments(currentComments);

        setTimeout(() => {
          handleCommentScroller();
        }, 0);
      });
    };

    (async () => {
      await fetchChat();
    })();

    const fetchRealTimeContent = async () => {
      const contentsRef = firebase
        .database()
        .ref('contextual_contents')
        .orderByChild('video_id')
        .equalTo(pgpqvid);

      let sortedArray = [];

      contentsRef.on('child_added', (data) => {
        sortedArray.push(data.val());

        if (!isFetching) {
          if (selectedVideo.attributes.status !== 'recorded') {
            sortedArray = sortedArray.slice(
              Math.max(sortedArray.length - 3, 0)
            );

            sortedArray.sort(function (a, b) {
              return a.frame_second - b.frame_second;
            });
          }
        }

        if (isPlayButtonClickedForTheFirstTime) {
          setContextProducts(sortedArray);
        } else {
          setContextProducts([]);
        }
      });

      contentsRef.on('child_changed', (data) => {
        sortedArray = sortedArray.filter((item) => item._id !== data.val()._id);

        if (isPlayButtonClickedForTheFirstTime) {
          setContextProducts(sortedArray);
        } else {
          setContextProducts([]);
        }
      });

      contentsRef.on('child_removed', (data) => {
        sortedArray = sortedArray.filter((item) => item._id !== data.val()._id);

        if (isPlayButtonClickedForTheFirstTime) {
          setContextProducts(sortedArray);
        } else {
          setContextProducts([]);
        }
      });
    };

    (async () => {
      await fetchRealTimeContent();
    })();

    const fetchProductsFromSelectedVideo = async () => {
      const url = `${API_BASE_URL}/videos/${pgpqvid}/products.json`;
      const res = await fetch(url, {
        method: 'GET',
        headers,
      });

      const products = (await res.json()).data;

      setProducts(products);
    };

    fetchProductsFromSelectedVideo();

    const fetchViewerCart = async () => {
      if (viewer_id) {
        let res = await viewerCart(viewer_id);

        set_viewer_cart({
          lineItems: res?.attributes.line_items || [],
          total: res?.attributes.net_total || 0,
        });
      }
    };

    fetchViewerCart();

    return () => {
      setContextProducts([]);
    };
  }, [
    pgpqvid,
    page,
    set_viewer_cart,
    viewer_id,
    isPlayButtonClickedForTheFirstTime,
    selectedVideo,
    isFetching,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${API_BASE_URL}/videos/${pgpqvid}/comments.json`;

    messageRef.current.value = '';

    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        sender_name: viewer.name,
        sender_email: viewer.email,
        body: message,
      }),
      headers,
    });

    setMessage('');
  };

  const handleCommentScroller = () => {
    const element = document.getElementById('comment-section');
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  useEffect(() => {
    async function sendLeads() {
      const res = await fetch(
        `${API_BASE_URL}/videos/${pgpqvid}/viewers.json`,
        {
          method: 'POST',
          body: JSON.stringify({
            email: viewer.email,
            name: viewer.name,
          }),
          headers,
        }
      );

      const id_viewer = (await res.json()).data;
      if (viewer_id === null) {
        set_viewer_id(id_viewer.id);
      }
    }
    if (viewer !== null) {
      sendLeads();
    }
  }, [viewer, pgpqvid, set_viewer_id, viewer_id]);

  const messageRef = useRef();
  const videoRef = useRef();

  const RenderComments = () => {
    if (!selectedVideo || comments.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img src="comments_icon.svg" alt="no message" />
          <br />
          <h4 style={{ color: '#6b6b6b', fontWeight: 400 }}>
            No comments to display.
          </h4>
        </div>
      );
    } else {
      return (
        <>
          {comments.map((comment) => (
            <Comment
              key={comment._id}
              sender={comment.sender_name}
              senderType={comment.sender_klass}
              logo={comment.logo}
              message={comment.body}
            />
          ))}
        </>
      );
    }
  };

  const handleVideoClicks = async (viewer_id, product_id) => {
    await fetch(`${API_BASE_URL}/videos/${pgpqvid}/video_clicks.json`, {
      method: 'POST',
      body: JSON.stringify({
        viewer_id,
        product_id,
      }),
      headers,
    });
  };

  const RenderProducts = () => {
    if (isFetching || selectedVideo.attributes.products.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '-2.5rem',
          }}
        >
          <img src="product_icon.svg" alt="no message" />
          <br />
          <h4 style={{ color: '#6b6b6b', fontWeight: 400 }}>
            No products to display.
          </h4>
        </div>
      );
    } else {
      const currencySign = getCurrencySign(selectedVideo.attributes.currency);

      if (products.length > 0) {
        return (
          <>
            {products.map((product) => (
              <ProductContainer
                goTo={() => {
                  handleVideoClicks(viewer_id, product.attributes.id.$oid);
                  window.open(product.attributes.product_page_url, '_blank');
                }}
                images={product.attributes.images}
                currencySign={currencySign}
                price={product.attributes.price}
                product_name={product.attributes.name}
                key={product.attributes.id.$oid}
              />
            ))}
          </>
        );
      } else {
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '-2.5rem',
            }}
          >
            <img src="product_icon.svg" alt="no message" />
            <br />
            <h4 style={{ color: '#6b6b6b', fontWeight: 400 }}>
              No products to display.
            </h4>
          </div>
        );
      }
    }
  };

  const handleIntegratedProductOnClick = (productId) => {
    setIsViewingIntegratedProduct(true);
    const product = products.find((p) => p.attributes.id.$oid === productId);
    setSelectedIntegratedProduct(product);
  };

  const handleBackToProductListOnClick = () => {
    setIsViewingIntegratedProduct(false);
    setIsViewingCart(false);
    setSelectedIntegratedProduct({});
  };

  const handleCartOnClick = () => {
    setIsViewingCart(true);
  };

  const RenderIntegratedCheckoutProducts = () => {
    if (isFetching || selectedVideo.attributes.products.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '-2.5rem',
          }}
        >
          <img src="product_icon.svg" alt="no message" />
          <br />
          <h4 style={{ color: '#6b6b6b', fontWeight: 400 }}>
            No products to display.
          </h4>
        </div>
      );
    } else {
      const currencySign = getCurrencySign(selectedVideo.attributes.currency);

      if (products.length > 0) {
        return (
          <>
            {products.map((product) => (
              <IntegratedProductsContainer
                key={product.attributes.id.$oid}
                handleIntegratedProductOnClick={() =>
                  handleIntegratedProductOnClick(product.attributes.id.$oid)
                }
                images={product.attributes.images}
                price={product.attributes.price}
                currencySign={currencySign}
                productName={product.attributes.name}
              />
            ))}
          </>
        );
      } else {
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '-2.5rem',
            }}
          >
            <img src="product_icon.svg" alt="no message" />
            <br />
            <h4 style={{ color: '#6b6b6b', fontWeight: 400 }}>
              No products to display.
            </h4>
          </div>
        );
      }
    }
  };

  const handlePlayButtonOnClick = () => {
    setIsPlayButtonClickedForTheFirstTime(true);
    setVideoProps({
      opacity: 0,
      playing: true,
      controls: false,
      muted: false,
    });
  };

  const pageReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 3500);
  };

  const handleUpComingVideoOnclick = async () => {
    const url = `${API_BASE_URL}/videos/${pgpqvid}.json`;

    let res = await fetch(url, {
      method: 'GET',
      headers,
    });

    const videoData = (await res.json()).data;

    if (videoData.attributes.status === DRAFT) {
      toast.success(
        'The event has not started yet. Please try again sometime.'
      );
    }

    if (videoData.attributes.status === UPCOMING) {
      toast.success(
        videoData.attributes.event_upcoming_title_text +
          ' ' +
          moment(videoData.attributes.scheduled_time).format('D MMMM, hA') +
          '.'
      );
    }

    if (videoData.attributes.status === LIVE) {
      toast.success(
        'The event has started! This page will reload in a few seconds.'
      );

      pageReload();
    }

    if (videoData.attributes.status === RECORDED) {
      toast.success(
        'The event has ended. This page will reload in a few seconds so you can play the recorded video.'
      );

      pageReload();
    }
  };

  useEffect(() => {
    if (streamStatus === false) {
      setTimeout(() => history.push(`/?pqd=${pqd}`), 2000);
    }
  }, [streamStatus, history, pqd]);

  const handleContextProductOnClick = async (
    viewerId,
    productId,
    productTargetURL
  ) => {
    handleVideoClicks(viewerId, productId);

    if (isIntegratedCheckout) {
      setIsViewingCart(false);

      const selectedContextProduct = products.find((p) => p.id === productId);

      // If custom content push
      if (selectedContextProduct === undefined) {
        return window.open(productTargetURL, '_blank');
      }

      setSelectedIntegratedProduct(selectedContextProduct);
      setIsViewingIntegratedProduct(true);
    } else {
      window.open(productTargetURL, '_blank');
    }
  };

  const RenderContextProductRecorded = (time) => {
    let recordedContextProductsToDisplay = contextProducts.filter((product) => {
      if (product.frame_second_exit === undefined) {
        return time >= product.frame_second_enter;
      } else {
        return (
          time >= product.frame_second_enter &&
          time <= product.frame_second_exit
        );
      }
    });

    if (recordedContextProductsToDisplay.length > 3) {
      recordedContextProductsToDisplay = recordedContextProductsToDisplay.slice(
        Math.max(recordedContextProductsToDisplay.length - 3, 0)
      );
    }

    return recordedContextProductsToDisplay ? (
      <>
        {recordedContextProductsToDisplay.map((product) => {
          return (
            <ContextProductWeb
              key={product._id}
              image_url={product.image_url}
              handleContextProductOnClick={() => {
                handleContextProductOnClick(
                  viewer_id,
                  product.product_id,
                  product.target_url
                );
              }}
              description={product.description}
              currencySign={getCurrencySign(selectedVideo.attributes.currency)}
            />
          );
        })}
      </>
    ) : null;
  };

  const streamEndedHandler = () => {
    var stream = setTimeout(() => {
      fetchVideos(pqd).then((res) => {
        var toPlayVideo = res.video.videos.filter(
          (video) => video.id === pgpqvid
        )[0];
        if (toPlayVideo.attributes.status === 'recorded') {
          setStreamStatus(false);
          setContextProducts([]);
          clearTimeout(stream);
        }
      });
    }, 5500);
  };

  const RenderContextProductLive = () => {
    let liveContextProducts = contextProducts.filter(
      (contextProduct) => contextProduct.frame_second_exit === undefined
    );

    return liveContextProducts ? (
      <>
        {liveContextProducts.map((product) => (
          <ContextProductWeb
            key={product._id}
            image_url={product.image_url}
            handleContextProductOnClick={() => {
              handleContextProductOnClick(
                viewer_id,
                product.product_id,
                product.target_url
              );
            }}
            description={product.description}
            currencySign={getCurrencySign(selectedVideo.attributes.currency)}
          />
        ))}
      </>
    ) : null;
  };

  return (
    <div
      className="play-shop-container-desktop"
      style={{ position: 'relative' }}
    >
      {viewer && (
        <>
          <div className="comment-container">
            <div className="comment-header">
              <p>{!isFetching && selectedVideo.attributes.comment_label}</p>
            </div>
            {!(comments.length < 30) && (
              <p
                className="comment-load-more"
                onClick={() => setPage((page) => page + 30)}
              >
                Load More
              </p>
            )}
            <div className="comment-section" id="comment-section">
              {!isFetching && <RenderComments />}
            </div>
            <div className="comment-input-container">
              <form className="comment-input" onSubmit={handleSubmit}>
                {!isFetching ? (
                  <input
                    ref={messageRef}
                    onChange={() => setMessage(messageRef.current.value)}
                    type="text"
                    placeholder={selectedVideo.attributes.comment_text}
                  />
                ) : (
                  <input
                    ref={messageRef}
                    onChange={() => setMessage(messageRef.current.value)}
                    type="text"
                  />
                )}

                <input type="image" name="submit" src="send.svg" alt="Submit" />
              </form>
            </div>
          </div>
          <div className="video-container">
            {!videoProps.playing && isFetching && (
              <div>
                <img src="loading.svg" alt="loading" />
                <br />
                <h4 style={{ color: '#6b6b6b' }}>
                  Please wait for a moment...
                </h4>
              </div>
            )}

            {(videoProps.playing || !isFetching) && (
              <>
                <div className="context-container-web">
                  <div
                    className="left-arrow"
                    onClick={() => history.push(`/?pqd=${pqd}`)}
                  >
                    <img src="left-arrow.svg" alt="shop-arrow" />
                  </div>

                  <div className="context-products-container-web">
                    {contextProducts &&
                      selectedVideo.attributes.status === 'recorded' &&
                      RenderContextProductRecorded(frameSecond)}

                    {contextProducts &&
                      selectedVideo.attributes.status === 'live' && (
                        <RenderContextProductLive />
                      )}
                  </div>
                </div>
                <div
                  className="close-button-mobile"
                  onClick={() => window.parent.postMessage('close', '*')}
                >
                  <img src="close.svg" alt="close" />
                </div>
              </>
            )}

            {/* For Upcoming and Draft */}
            {!isFetching &&
              (selectedVideo.attributes.status === 'upcoming' ||
                selectedVideo.attributes.status === 'draft') &&
              viewer && (
                <>
                  <div
                    onClick={() => {
                      handleUpComingVideoOnclick();
                    }}
                    className="poster-container-desktop"
                  >
                    <div className="event-banner-container">
                      <img className="poster-desktop" src={BannerURL} alt="" />
                      <button type="button" className="play-button-container">
                        <img
                          className="play-button-img-desktop"
                          src="play.svg"
                          alt="play"
                        />
                      </button>

                      {selectedVideo.attributes.scheduled_time ? (
                        <>
                          <p className="event-text">
                            {selectedVideo.attributes.event_upcoming_title_text}
                          </p>

                          <p className="upcoming-event-date">
                            {moment(
                              selectedVideo.attributes.scheduled_time
                            ).format('D MMMM, hA')}
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            className="event-text"
                            style={{
                              padding: '0 1rem',
                              textAlign: 'center',
                            }}
                          >
                            The event has not started yet. Please try again
                            sometime.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

            {/* For live */}
            {!isFetching &&
            streamStatus &&
            selectedVideo.attributes.status === 'live' ? (
              <>
                {isPlayButtonClickedForTheFirstTime ? (
                  <ReactPlayer
                    className="react-player"
                    url={selectedVideo.attributes.livestream_url}
                    ref={videoRef}
                    width="100%"
                    height="100%"
                    muted={videoProps.muted}
                    playing={videoProps.playing}
                    controls={true}
                    onReady={() => {
                      sessionStorage.removeItem('intendedRoute');
                    }}
                    onProgress={(state) => {
                      updateFrameSecond(state.playedSeconds);
                    }}
                    onBuffer={() => {
                      streamEndedHandler();
                    }}
                    onStart={() => {
                      handleCommentScroller();
                    }}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        controlsList: 'nodownload',
                      },
                    }}
                  />
                ) : (
                  <div
                    onClick={() => {
                      handlePlayButtonOnClick();
                      setIsPlayButtonClickedForTheFirstTime(true);
                    }}
                    className="poster-container-desktop"
                  >
                    <div className="event-banner-container">
                      <img className="poster-desktop" src={BannerURL} alt="" />
                      <button type="button" className="play-button-container">
                        <img
                          className="play-button-img-desktop"
                          src="play.svg"
                          alt="play"
                        />
                      </button>

                      <p className="event-text">Please click the play button</p>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            {/* When livestream ends */}
            {!isFetching && !streamStatus && viewer && (
              <div className="poster-container-desktop">
                <div className="event-banner-container">
                  <img className="poster-desktop" src={BannerURL} alt="" />

                  <p className="stream-ended-text">
                    The stream has ended. Thanks for tuning in!
                  </p>
                  <p className="stream-ended-sub-text">
                    You will be redirected to the videos library in a few
                    seconds.
                  </p>
                </div>
              </div>
            )}

            {/* For recorded */}
            {!isFetching && selectedVideo.attributes.status === 'recorded' &&  (
              <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                  <div className="poster-container-desktop" style={{ display: isPlayButtonClickedForTheFirstTime ? 'none' : null }}>
                      <div className="event-banner-container">
                        <img className="poster-desktop" src={BannerURL} alt="" />
                        {
                          setPlay && (
                            <button onClick={() => {
                              handlePlayButtonOnClick();
                            }}
                            type="button" className="play-button-container">
                            <img
                              className="play-button-img-desktop"
                              src="play.svg"
                              alt="play"
                            />
                        </button>
                          )
                        }

                        <p className="event-text">Please click the play button</p>
                      </div>
                    </div>
                    <ReactPlayer
                      className="react-player"
                      url={selectedVideo.attributes.livestream_url}
                      width="100%"
                      height="100%"
                      muted={videoProps.muted}
                      playing={videoProps.playing}
                      style={{
                        position: 'absolute',
                        zIndex: 0
                      }}
                      controls={true}
                      onReady={() => {
                        sessionStorage.removeItem('intendedRoute');
                      }}
                      onProgress={(state) => {
                        updateFrameSecond(state.playedSeconds);
                      }}
                      onStart={() => {
                        handleCommentScroller();
                      }}
                      onEnded={() => {
                        setContextProducts([]);
                        setIsPlayButtonClickedForTheFirstTime(false);
                      }}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: 'nodownload',
                            preload: 'auto',
                            onCanPlay: () => {
                              setPlayVideo(!setPlay)
                            }
                          },
                        },
                      }}
                    />
              </div>
            )}
          </div>

          <div className="shop-desktop-container">
            <div className="shop-header">
              <div className="shop-header-left-panel-desktop">
                {(isViewingIntegratedProduct || isViewingCart) && (
                  <button
                    onClick={handleBackToProductListOnClick}
                    className="arrow-icon-button-desktop"
                  >
                    <svg
                      className="left-arrow-shop-header-desktop"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                  </button>
                )}

                {!isFetching && (
                  <p style={{ textTransform: 'uppercase' }}>
                    {isViewingCart ? (
                      `${selectedVideo.attributes.cart_text}`
                    ) : (
                      <>
                        {isViewingIntegratedProduct
                          ? `${selectedVideo.attributes.back_to_products_text}`
                          : `${selectedVideo.attributes.shop_label}`}
                      </>
                    )}
                  </p>
                )}
              </div>

              <div className="shop-header-right-panel-desktop">
                {isIntegratedCheckout && (
                  <div className="shop-header-right-panel-desktop">
                    <button type="button" onClick={handleCartOnClick}>
                      <img
                        className="cart-desktop"
                        src="shopping-cart.svg"
                        alt="Cart"
                      />
                      <div className="cart-badge-number">
                        {cart.lineItems.length || '0'}
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>

            {isViewingCart && selectedVideo ? (
              <CartDesktop
                lineItems={cart.lineItems}
                checkoutTotal={cart.total}
                currencySign={getCurrencySign(
                  selectedVideo.attributes.currency
                )}
                updateCartItem={updateCartItem}
                deleteViewerCart={deleteViewerCart}
                removeCartItem={removeCartItem}
                viewer_id={viewer_id}
                removeText={selectedVideo.attributes.remove_button_text}
                checkoutText={selectedVideo.attributes.checkout_btn}
                itemRemovedText={selectedVideo.attributes.item_removed_msg}
              />
            ) : (
              <div className="shop-products-container">
                {isViewingIntegratedProduct ? (
                  <IntegratedProductDetailsPage
                    lineItemId={selectedIntegratedProduct.attributes.id.$oid}
                    images={selectedIntegratedProduct.attributes.images}
                    currency={selectedVideo.attributes.currency}
                    price={selectedIntegratedProduct.attributes.price}
                    name={selectedIntegratedProduct.attributes.name}
                    variants={selectedIntegratedProduct.attributes.variants}
                    addProductToCart={addProductToCart}
                    removeCartItem={removeCartItem}
                    viewer_id={viewer_id}
                    cartlineItems={cart.lineItems}
                    addToCartText={selectedVideo.attributes.add_to_cart_text}
                    removeFromCartText={
                      selectedVideo.attributes.remove_button_text
                    }
                    itemAddedText={selectedVideo.attributes.item_added_msg}
                    itemRemovedText={selectedVideo.attributes.item_removed_msg}
                  />
                ) : (
                  <>
                    {isIntegratedCheckout ? (
                      <RenderIntegratedCheckoutProducts />
                    ) : (
                      <RenderProducts />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    frameSecond: state.video.frameSecond,
    viewer: state.auth.viewer,
    viewer_id: state.auth.viewer_id,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVideos: (id) => dispatch(fetchVideos(id)),
    updateFrameSecond: (frame) =>
      dispatch({ type: 'FRAME_SECOND', payload: frame }),
    set_viewer_id: (id) => dispatch({ type: 'SET_VIEWER_ID', payload: id }),
    set_viewer_cart: (cart) => dispatch({ type: 'ADD_TO_CART', payload: cart }),
    addProductToCart: ({ variant_id, quantity, viewer_id }) =>
      dispatch(addProductToCart({ variant_id, quantity, viewer_id })),
    deleteViewerCart: (viewer_id) => dispatch(deleteViewerCart(viewer_id)),
    removeCartItem: (line_item_id) => dispatch(removeCartItem(line_item_id)),
    updateCartItem: ({ line_item_id, quantity }) =>
      dispatch(updateCartItem({ line_item_id, quantity })),
  };
};

export default memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayDesktop))
);
